@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply font-inter antialiased h-full text-gray-200;
  }

  @supports (font-variation-settings: normal) {
    html {
      @apply font-inter-var;
    }
  }

  /* stylelint-disable-next-line selector-id-pattern */
  #__next {
    @apply h-full;
  }

  a,
  button {
    @apply cursor-pointer focus:outline-none focus:ring-0;
  }

  a[disabled],
  button[disabled] {
    @apply cursor-default pointer-events-none;
  }
}

@layer components {
  .pretty-link {
    @apply underline rounded transition-colors hover:text-white hover:bg-gray-800;
  }
}
